import { Injectable } from '@angular/core';
import {
  BreadcrumbItem,
  DetailsTab,
} from '../../../../core/modals/details.modal';

@Injectable({ providedIn: 'root' })
export class SLAContractDetailsConfig {
  breadcrumbs: BreadcrumbItem[] = [
    { label: 'SLA & Contracts', url: '/compliance/sla-contracts' },
    { label: '', url: '' },
  ];

  slaTabs: DetailsTab[] = [
    {
      link: '/compliance/sla-contracts/:id/overview',
      icon: 'overview-tab',
      label: 'Overview',
      isActive: true,
    },
    {
      link: '/compliance/sla-contracts/:id/preview',
      icon: 'pdf-view-tab',
      label: 'Preview',
      isActive: false,
    },
    {
      link: '/compliance/sla-contracts/:id/associated-items',
      icon: 'associated-items-tab',
      label: 'Associated Items',
      isActive: false,
    },

    // {
    //     link: '/compliance/sla-contracts/:id/assessments',
    //     icon: 'pdf-view-tab',
    //     label: 'Assessments',
    //     isActive: false,
    // },
    {
      link: '/compliance/sla-contracts/:id/action-plans',
      icon: 'action-plans-tab',
      label: 'Action Plans',
      isActive: false,
    },
  ];
}
